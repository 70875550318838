@import "../../theme.scss";

.not-found {
  height: 100vh;
  width: 100%;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}
