$primary: #393939;
$accent: #11593f;
$black: #393939;
$white: #ffffff;
$yellow: #feda7b;

// base unit
$scale0: 4px;
$scale1: 8px;
$scale2: 16px;
$scale3: 24px;
$scale4: 32px;
$scale5: 40px;
$scale6: 48px;
$scale7: 56px;
$scale8: 64px;
$scale9: 72px;
$scale10: 80px;

$nav-spacer-L: 240px;
$nav-spacer-M: 128px;
$nav-spacer-S: $scale6;

:export {
  primary: $primary;
  accent: $accent;
  black: $black;
  white: $white;
  scale0: $scale0;
  scale1: $scale1;
  scale2: $scale2;
  scale3: $scale3;
  scale4: $scale4;
  scale5: $scale5;
  scale6: $scale6;
  scale7: $scale7;
  scale8: $scale8;
  scale9: $scale9;
  scale10: $scale10;
  nav-spacer-L: $nav-spacer-L;
  nav-spacer-M: $nav-spacer-M;
  nav-spacer-S: $nav-spacer-S;
}

@mixin Display {
  font-family: "Geo";
  font-weight: 400;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.02em;
}

@mixin Header {
  font-family: "Inter";
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
}

@mixin Body {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

@mixin Body-bold {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

// @mixin display-1 {
//   font-family: "DM Serif Display-Regular";
//   font-weight: normal;
//   font-size: 48px;
//   line-height: 72px;
//   letter-spacing: 0.02em;
// }

// @mixin display-2 {
//   font-family: "DM Serif Display-Regular";
//   font-weight: normal;
//   font-size: 28px;
//   line-height: 42px;
//   letter-spacing: 0.02em;
// }

// @mixin header-1 {
//   font-family: "Inter";
//   font-weight: 600;
//   font-size: 24px;
//   line-height: 36px;
//   letter-spacing: 0.02em;
// }

// @mixin subheader-1 {
//   font-family: "Inter";
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 27px;
//   letter-spacing: 0.02em;
// }

// @mixin body-1 {
//   font-family: "Inter";
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: 0.01em;
// }

// @mixin link-1 {
//   font-family: "Inter";
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: 0.01em;
// }

// @mixin body-2 {
//   font-family: "Inter";
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 18px;
//   letter-spacing: 0.01em;
// }

// @mixin link-2 {
//   font-family: "Inter";
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 18px;
//   letter-spacing: 0.01em;
// }

// @mixin link {
//   color: $accent;
//   text-decoration: none;
// }
