.text-list {
  ol,
  ul {
    margin-block-start: 8px;
    margin-block-end: 48px;
    padding-inline-start: 24px;

    li {
      list-style-type: disc;
      margin-bottom: 8px;
    }
  }
}
