@import "./theme.scss";
@import "./styles/grid.scss";

@font-face {
  font-family: "Inter";
  src: local("Inter-Regular"),
    url(./assets/fonts/Inter-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Geo";
  src: local("Geo-Oblique"),
    url(./assets/fonts/Geo-Oblique.ttf) format("truetype");
}

@font-face {
  font-family: "DM Serif Display-Regular";
  src: local("DM Serif Display-Regular"),
    url(./assets/fonts/DMSerifDisplay-Regular.ttf) format("truetype");
}

body {
  background-color: $white;
  z-index: 1;
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $accent;
}

.action-link {
  text-decoration: none;
  display: inline;
  // @include link;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

pre {
  white-space: pre-wrap;
  background: #eee;
  border-radius: 8px;
  padding: 16px 24px;
  code {
    font-family: "Courier New", Courier, monospace;
  }
}

code.inline {
  background-color: #eee;
  padding: 4px 8px;
  border-radius: 8px;
}

.mobile-menu {
  position: fixed;
  background-color: $white;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  & > .menu-content {
    list-style-type: none;
    margin: 0;
    width: 100%;
    align-self: self-end;
    margin-block-start: 0;
    padding-inline-start: 0;

    & > li {
      width: 100%;
      border-top: 2px solid $black;
      height: 72px;
      padding-left: 24px;
      display: inline-flex;
      align-items: center;

      &.last {
        justify-content: center;
        padding-left: 0;
      }

      &.first {
        border: none;
      }
    }
  }
}
