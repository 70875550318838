@import "../../../theme.scss";

.prose {
  padding: 96px 240px 240px 240px;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  i {
    display: block;
  }
  a {
    color: $primary;
  }
  img {
    margin-bottom: 16px;
    width: 100%;
    height: auto;
    border: 2px solid $black;

    &.SR-logo {
      height: 120px;
      margin: 0 auto;
      align-items: center;
      display: inline-block;
      flex-direction: column;
      margin-top: 16px;
      margin-bottom: 16px;
      border: none;
    }
  }
}
.summary-text-link {
  display: inline-flex;
  flex-direction: column;
}

.img-caption {
  font-style: italic;
  margin-bottom: 16px;
}

.prose-block {
  margin-bottom: 16px;
}

@media only screen and (max-width: 1000px) {
  .prose {
    padding: 120px 24px 80px 24px;
  }
}
