@import "../../theme.scss";

.block-header {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 770px) {
  .prose-block > .block-header {
    display: block;
  }
}
.work-block-body {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 66%;
  grid-column-gap: 24px;
  grid-row-gap: 0px;

  & > .work-block-details {
    width: 100%;

    & > .work-block-detail-row {
      width: inherit;
      display: inline-flex;
      justify-content: space-between;
    }
  }
  & > .work-img {
    & > img {
      width: 100%;
      height: auto;

      transition: all 0.6s ease-in-out;
      border: 2px solid $accent;
      background-color: $accent;
      aspect-ratio: 4/3;
      box-shadow: 8px 8px $accent;

      &:hover {
        transform: translate(4px, 4px);
        box-shadow: none;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .work-block {
    padding: 24px;
  }
  .work-block-body {
    grid-template-columns: auto;
  }
}
