.container {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 0px;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-content: space-around;
}

.col,
.cols {
  display: inline-flex;
  grid-gap: 0px;
  grid-template-columns: subgrid;
}

.two {
  grid-column: auto / span 2;
}
.three {
  grid-column: auto / span 3;
}
.four {
  grid-column: auto / span 4;
}
.five {
  grid-column: auto / span 5;
}
.six {
  grid-column: auto / span 6;
}
.seven {
  grid-column: auto / span 7;
}
.eight {
  grid-column: auto / span 8;
}
.nine {
  grid-column: auto / span 9;
}
.ten {
  grid-column: auto / span 10;
}
.eleven {
  grid-column: auto / span 11;
}
.twelve {
  grid-column: auto / span 12;
}

@media only screen and (max-width: 550px) {
  .row {
    grid-gap: 0px;
  }
}
