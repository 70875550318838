@import "../../theme.scss";

.image-with-caption {
  font-style: italic;
  margin-top: 8px;
  margin-bottom: 16px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  img {
    margin-bottom: 8px;
    border: 2px solid $accent;
  }
}

.iphone-img {
  max-width: 375px;
}
